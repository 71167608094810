import React, { useState, useEffect } from "react";
import styles from "./UserContent.module.css";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "@_actions/user.actions";
import { validateEmail, isNull, getUserDataFromJwt } from "@_utils/helpers";
import userStates from "@_configs/userStates.config";
import classnames from "classnames";
import AreYouSurePopup from "@_components/popups/AreYouSurePopup/AreYouSurePopup";

import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import PersonIcon from "@material-ui/icons/Person";

function UserContent() {
  const jwtData = getUserDataFromJwt();
  const dispatch = useDispatch();

  const userReducer = useSelector((state) => state.userReducer);

  const [openAreYouSurePopup, setOpenAreYouSurePopup] = useState(false);

  const [userDetails, setUserDetails] = useState({
    name: (userReducer.selected_user && userReducer.selected_user.name) || "",
    email: (userReducer.selected_user && userReducer.selected_user.email) || "",
    role:
      (userReducer.selected_user && userReducer.selected_user.role) || "AGENT",
  });

  useEffect(() => {
    setUserDetails({
      name: (userReducer.selected_user && userReducer.selected_user.name) || "",
      email:
        (userReducer.selected_user && userReducer.selected_user.email) || "",
      role:
        (userReducer.selected_user && userReducer.selected_user.role) ||
        "AGENT",
    });
  }, [userReducer.selected_user]);

  const [checkbox, setCheckbox] = useState(false);
  const [btnPressed, setBtnPressed] = useState(false);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setUserDetails((userDetails) => ({
      ...userDetails,
      [name]: value,
    }));
  }
  const handleAddNewUser = () => {
    setBtnPressed(true);

    let data = {
      ...userDetails,
      role: !userDetails.role ? "AGENT" : userDetails.role.toUpperCase(),
    };

    dispatch(userActions.addUser(data));
  };

  const handleCloseNewUser = () => {
    dispatch(userActions.addUserState(false));
  };

  const handleDeactivateUser = () => {
    dispatch(userActions.deactivateUser(userReducer.selected_user.user_id));
  };

  const handleActivateUser = () => {
    dispatch(userActions.activateUser(userReducer.selected_user.user_id));
  };

  const handleUpdateUser = () => {
    setBtnPressed(true);

    let data = {
      name: userDetails.name,
      role: !userDetails.role ? "AGENT" : userDetails.role.toUpperCase(),
    };
    dispatch(userActions.updateUser(userReducer.selected_user.user_id, data));
  };

  const handleDeleteUser = () => {
    dispatch(userActions.deleteUser(userReducer.selected_user.user_id));
  };

  useEffect(() => {
    if (
      userReducer.status.USER_ACTIVATE_PUT === "success" ||
      userReducer.status.USER_DEACTIVATE_PUT === "success"
    ) {
      let updatedSelectedUser = userReducer.users.list.filter(
        (user) => user.user_id === userReducer.selected_user.user_id
      );
      dispatch(userActions.selectUser(updatedSelectedUser[0]));
    }
  }, [
    userReducer.status.USER_ACTIVATE_PUT,
    userReducer.status.USER_DEACTIVATE_PUT,
    userReducer.users.list,
    userReducer.selected_user,
    dispatch,
  ]);

  useEffect(() => {
    if (userReducer.status.USER_DELETE === "success") {
      dispatch(userActions.selectUser());
    }
  }, [userReducer.status.USER_DELETE, dispatch]);

  return (
    <div className={styles.root}>
      <div
        className={
          (userReducer.add_user_state && styles.user_header) ||
          (userReducer.selected_user &&
            userReducer.selected_user.state &&
            userReducer.selected_user.state === "DEACTIVATED" &&
            classnames(styles.user_header, styles.user_header_deactivated)) ||
          (userReducer.selected_user &&
            userReducer.selected_user.state &&
            userReducer.selected_user.state === "INVITATION_PENDING" &&
            classnames(styles.user_header, styles.user_header_pending)) ||
          (userReducer.selected_user &&
            userReducer.selected_user.state &&
            userReducer.selected_user.state === "ACTIVE" &&
            classnames(styles.user_header))
        }
      >
        <div className={styles.user_name_container}>
          <div className={styles.icon_container}>
            {(userReducer.selected_user &&
              userReducer.selected_user.state &&
              userStates[userReducer.selected_user.state].icon) || (
              <PersonIcon />
            )}

            <div>
              {(userReducer.selected_user &&
                userReducer.selected_user.role &&
                userReducer.selected_user.role.toLowerCase()) ||
                (!userDetails.role && "AGENT") ||
                userDetails.role.toLowerCase()}
            </div>
          </div>
          <h2>
            {(userReducer.selected_user && userReducer.selected_user.name) ||
              "New User"}
          </h2>
        </div>
        {userReducer.selected_user &&
          userReducer.selected_user.state === "INVITATION_PENDING" && (
            <Button
              className={styles.btn_delete}
              onClick={() => setOpenAreYouSurePopup(true)}
            >
              Delete User
            </Button>
          )}
        {userReducer.selected_user &&
          userReducer.selected_user.state === "ACTIVE" &&
          jwtData.username !== userReducer.selected_user.name && (
            <Button
              className={styles.btn_delete}
              onClick={() => handleDeactivateUser()}
            >
              Deactivate User
            </Button>
          )}
        {userReducer.selected_user &&
          userReducer.selected_user.state === "DEACTIVATED" && (
            <Button
              className={styles.btn_delete}
              onClick={() => handleActivateUser()}
            >
              Activate User
            </Button>
          )}
      </div>
      <div className={styles.content_container}>
        <div>
          {userReducer.add_user_state && (
            <div className={styles.label}>
              <p>* Required Fields</p>
            </div>
          )}
          <div className={styles.input_container}>
            <TextfieldInput
              label="Name"
              placeholder="Name"
              onChange={handleInputChange}
              name="name"
              value={userDetails.name}
              required
              error={btnPressed && isNull(userDetails.name)}
              disabled={
                userReducer.selected_user &&
                userReducer.selected_user.state === "DEACTIVATED"
              }
            />
          </div>
          <div className={styles.input_container}>
            <TextfieldInput
              label="Email"
              placeholder="Email"
              onChange={handleInputChange}
              name="email"
              value={userDetails.email}
              required
              disabled={
                userReducer.selected_user && userReducer.selected_user.email
              }
              error={
                btnPressed &&
                (isNull(userDetails.email) || !validateEmail(userDetails.email))
              }
            />
          </div>
          <div className={styles.input_container}>
            <TextfieldInput
              select
              label="Role"
              placeholder="Role"
              onChange={handleInputChange}
              defaultValue="AGENT"
              name="role"
              value={userDetails.role}
              required
              disabled={
                userReducer.selected_user &&
                userReducer.selected_user.state === "DEACTIVATED"
              }
            >
              <MenuItem key={"AGENT"} value={"AGENT"}>
                {"Agent"}
              </MenuItem>
              <MenuItem key={"ADMIN"} value={"ADMIN"}>
                {"Admin"}
              </MenuItem>
            </TextfieldInput>
          </div>
        </div>
        {userReducer.selected_user &&
          (userReducer.selected_user.state === "INVITATION_PENDING" ||
            userReducer.selected_user.state === "ACTIVE") && (
            <div className={styles.btn_save_container}>
              <Button
                className={styles.btn_save}
                onClick={() => handleUpdateUser()}
              >
                Save
              </Button>
            </div>
          )}

        {/* {userReducer.selected_user &&
            userReducer.selected_user.state === "INVITATION_PENDING" && (
              <Button className={styles.btn_send_invite_again}>
                Send invitation again
              </Button>
            )} */}

        {userReducer.add_user_state && (
          <div className={styles.btn_container}>
            {userReducer.add_user_state && (
              <Button
                className={styles.btn_cancel}
                onClick={() => handleCloseNewUser()}
              >
                Cancel
              </Button>
            )}

            <div>
              {userReducer.add_user_state && (
                <div className={styles.accept_container}>
                  <Checkbox
                    color="default"
                    value={checkbox}
                    onChange={(e) => setCheckbox(!checkbox)}
                  />
                  <div className={styles.checkbox_label}>
                    I am aware that adding a user will result in a monthly
                    charge. Paid fees for users deactivated during billing
                    period will be used for this user for the remainder of this
                    billing period.
                  </div>
                </div>
              )}

              {userReducer.add_user_state && !checkbox && (
                <Button className={styles.btn_save_and_send_invite} disabled>
                  Save and send invitation
                </Button>
              )}
              {userReducer.add_user_state && checkbox && (
                <Button
                  className={styles.btn_save_and_send_invite}
                  onClick={() => handleAddNewUser()}
                >
                  Save and send invitation
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      <AreYouSurePopup
        open={openAreYouSurePopup}
        close={() => setOpenAreYouSurePopup(false)}
        onClick={handleDeleteUser}
      >
        Are you sure you want to delete this user?
      </AreYouSurePopup>
    </div>
  );
}

export default UserContent;
