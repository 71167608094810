import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "@_actions/auth.actions";
import { getUserDataFromJwt, getEmail } from "@_utils/helpers";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ChangePassword from "@_components/ChangePassword/ChangePassword.js";
import ChangeName from "@_components/ChangeName/ChangeName.js";
import ChangeSignature from "@_components/ChangeSignature/ChangeSignature";

import styles from "./Profile.module.css";

function ProfileComponent() {
  const dispatch = useDispatch();
  const menuRef = useRef();
  const userReducer = useSelector((state) => state.userReducer);

  const email = localStorage.getItem("user") ? getEmail() : { email: "" };
  const jwtData = getUserDataFromJwt();
  const [profileName, setProfileName] = useState(jwtData.username);
  const [signature, setSignature] = useState(userReducer.user_signature);

  // replace the username in local storage, here below also should be updated and remove setItem
  useEffect(() => {
    profileName &&
      localStorage.setItem(
        "jwtData",
        JSON.stringify({
          username: profileName,
          customer_name: jwtData.customer_name,
        })
      );
  }, [profileName, jwtData.customer_name]);

  useEffect(() => {
    setSignature(userReducer.user_signature);
  }, [userReducer.user_signature]);

  const useStyles = makeStyles({
    Menu: {
      "& div": {
        top: 87,
        right: "0 !important",
        boxShadow: "0px 3px 6px var(--color-theme-cloud)",
        border: "1px solid var(--color-theme-cloud)",
        overflow: "scroll",
      },
    },
    MenuItem: {
      height: 44,
      overflow: "scroll",
      "&:hover": {
        backgroundColor: `var(--color-theme-lightblue) !important`,
      },
    },
  });

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = (event) => {
    setAnchorEl(menuRef);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const menuItems = [
    "Edit profile",
    "Change password",
    "Edit email signature",
    "Logout",
  ];
  const [displayChangePassword, setDisplayChangePassword] = useState(false);
  const [displayChangeName, setDisplayChangeName] = useState(false);
  const [displayChangeSignature, setDisplayChangeSignature] = useState(false);

  useEffect(() => {
    setProfileName(profileName);
  }, [profileName]);

  const onMenuItemClick = (event, index) => {
    if (index === 0) {
      setDisplayChangePassword(false);
      setDisplayChangeSignature(false);
      setDisplayChangeName(true);
      closeMenu();
    } else if (index === 1) {
      setDisplayChangeName(false);
      setDisplayChangeSignature(false);
      setDisplayChangePassword(true);
      closeMenu();
    } else if (index === 2) {
      setDisplayChangeName(false);
      setDisplayChangePassword(false);
      setDisplayChangeSignature(true);
      closeMenu();
    } else if (index === 3) {
      dispatch(authActions.logout());
    }
  };

  return (
    <div className={styles.right_container}>
      <div className={styles.user_container} onClick={openMenu}>
        <Button className={styles.MuiButtonBaseRoot}>
          <div className={styles.user_name}>
            <div>{profileName}</div>
            <div>{jwtData.customer_name}</div>
          </div>
          <AccountCircleIcon className={styles.user_icon} />
        </Button>
      </div>

      <Menu
        className={classes.Menu}
        keepMounted
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          style: {
            width: "340px",
          },
        }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 87, right: "0 !important" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={item}
            className={classes.MenuItem}
            onClick={(event) => onMenuItemClick(event, index)}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
      {displayChangePassword && (
        <ChangePassword
          displayChangePassword={displayChangePassword}
          setDisplayChangePassword={setDisplayChangePassword}
        />
      )}
      {displayChangeName && (
        <ChangeName
          displayChangeName={displayChangeName}
          setDisplayChangeName={setDisplayChangeName}
          userName={profileName}
          userEmail={email}
          setProfileName={setProfileName}
        />
      )}
      {displayChangeSignature && (
        <ChangeSignature
          displayChangeSignature={displayChangeSignature}
          setDisplayChangeSignature={setDisplayChangeSignature}
          userSignature={signature}
          userName={profileName}
          userEmail={email}
          setProfileName={setProfileName}
        />
      )}
    </div>
  );
}

export default ProfileComponent;
