import React, { useEffect, useState } from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import styles from "./SalutationsPlaceholder.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { Box } from "@material-ui/core";
import { DragHandle } from "@material-ui/icons";

function SalutationsPlaceholderPopup(props) {
  const [salutationSettings, setsalutationSettings] = useState({
    type: "salutation",
    priority: ["first_name", "last_name", "no_name"],
    salutations: {
      last_name: {
        male: "",
        female: "",
        unknown: "",
      },
      first_name: {
        male: "",
        female: "",
        unknown: "",
      },
      no_name: "",
    },
    created: "",
    updated: "",
  });
  const [addPlaceholder, setAddPlacholder] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const popupParagraph =
    "Note: while {{first_name}} is mandatory in the 'first name’ salutation and {{last_name}} is mandatory in the 'last name’ salutation, both may be used in all fields and will be rendered empty, if not available in the case.";

  useEffect(() => {
    if (props.salutation) {
      setsalutationSettings(props.salutation);
    } else {
      setsalutationSettings({
        type: "salutation",
        priority: ["first_name", "last_name", "no_name"],
        salutations: {
          last_name: {
            male: "",
            female: "",
            unknown: "",
          },
          first_name: {
            male: "",
            female: "",
            unknown: "",
          },
          no_name: "",
        },
        created: "",
        updated: "",
      });
    }
  }, [props]);

  const handleClose = () => {
    setsalutationSettings({
      type: "salutation",
      priority: ["first_name", "last_name", "no_name"],
      salutations: {
        last_name: {
          male: "",
          female: "",
          unknown: "",
        },
        first_name: {
          male: "",
          female: "",
          unknown: "",
        },
        no_name: "",
      },
      created: "",
      updated: "",
    });
    props.close();
  };

  const onUpdateSetting = (event, setting) => {
    const name = event.target.name;
    const value = event.target.value;
    setsalutationSettings((prev) => {
      return {
        ...prev,
        salutations: {
          ...prev.salutations,
          [setting]:
            setting === "no_name"
              ? value
              : {
                  ...prev.salutations[setting],
                  [name]: value,
                },
        },
      };
    });
  };

  // Function to reorder the list on drag
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // Function to handle drag end
  function onDragEnd(result) {
    if (!result.destination) return; // If the item was dropped outside of a droppable area

    if (result.destination.index === result.source.index) return; // If the item was dropped at the same position

    // Call reorder with the correct parameters
    const orderedsalutationSettings = reorder(
      salutationSettings.priority,
      result.source.index,
      result.destination.index
    );

    // Update state with the new order
    setsalutationSettings((prev) => {
      return {
        ...prev,
        priority: orderedsalutationSettings,
      };
    });
  }

  const handleSubmit = () => {
    setIsClicked(true);
    if (!addPlaceholder) {
      return;
    } else {
      setIsClicked(false);
    }
    const nowDate = new Date();

    const timedSettting = (prevsalutationSettings) => ({
      ...prevsalutationSettings,
      updated: nowDate,
      ...(prevsalutationSettings.created ? {} : { created: nowDate }),
    });

    setsalutationSettings((prevsalutationSettings) => {
      const updatedSetting = timedSettting(prevsalutationSettings);
      handleAfterStateUpdate(updatedSetting);
      return updatedSetting;
    });

    handleClose();
  };

  const handleAfterStateUpdate = (updatedSetting) => {
    props.onAdd("salutation", updatedSetting);
  };

  const title = (
    <>
      <h3 style={{ margin: 0 }}>SALUTATION</h3>
      <h5 style={{ margin: "2px 0 0 0", textTransform: "none" }}>
        The order of the options determines the order of preference.
      </h5>
    </>
  );

  const blockTitle = (id) => {
    switch (id) {
      case "last_name":
        return "Salutation, if last name is available:";

      case "first_name":
        return "Salutation, if first name is available:";

      case "no_name":
        return "Salutation without name:";

      default:
        return "";
    }
  };

  useEffect(() => {
    const salutation = salutationSettings.salutations;
    const last_name = salutation.last_name;
    const first_name = salutation.first_name;
    const no_name = salutation.no_name;
    if (
      last_name?.male?.trim().length > 0 &&
      last_name?.male?.includes("{{last_name}}") &&
      last_name?.female?.trim().length > 0 &&
      last_name?.female?.includes("{{last_name}}") &&
      last_name?.unknown?.trim().length > 0 &&
      first_name?.male?.trim().length > 0 &&
      first_name?.male?.includes("{{first_name}}") &&
      first_name?.female?.trim().length > 0 &&
      first_name?.female?.includes("{{first_name}}") &&
      first_name?.unknown?.trim().length > 0 &&
      no_name?.trim().length > 0
    ) {
      setAddPlacholder(true);
    } else {
      setAddPlacholder(false);
    }
  }, [salutationSettings.salutations]);

  const checkError = (setting, gender) => {
    if (isClicked) {
      const salutation = salutationSettings.salutations;
      const last_name = salutation.last_name;
      const first_name = salutation.first_name;
      const no_name = salutation.no_name;
      switch (setting) {
        case "last_name":
          if (
            (gender === "unknown" && !last_name[gender]?.trim().length > 0) ||
            (gender !== "unknown" &&
              (!last_name[gender]?.trim().length > 0 ||
                !last_name[gender]?.includes("{{last_name}}")))
          )
            return true;
          break;
        case "first_name":
          if (
            (gender === "unknown" && !first_name[gender]?.trim().length > 0) ||
            (gender !== "unknown" &&
              (!first_name[gender]?.trim().length > 0 ||
                !first_name[gender]?.includes("{{first_name}}")))
          )
            return true;
          break;

        case "no_name":
          if (!no_name?.trim().length > 0) return true;
          break;

        default:
          break;
      }
    }
    return false;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <DialogPopup
        title={title}
        subtitle="hello"
        open={props.open}
        onClose={handleClose}
      >
        <div>
          <p style={{ marginLeft: "25%", marginTop: "0" }}>
            Drag the items to change their order
          </p>
          <Droppable droppableId="list">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={styles.droppableContainer}
              >
                {salutationSettings.priority?.map((setting, index) => (
                  <Draggable key={setting} draggableId={setting} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.draggableItem}
                      >
                        <div className={styles.body}>
                          <p style={{ margin: 0 }}>{index + 1}.</p>
                          <div className={styles.block}>
                            <h5 className={styles.block_title}>
                              {blockTitle(setting)}
                            </h5>
                            <div className={styles.block_body}>
                              <div className={styles.block_inputs}>
                                {setting !== "no_name" && (
                                  <>
                                    <Box display="flex" alignItems="center">
                                      <p className={styles.block_label}>Male</p>
                                      <TextfieldInput
                                        required
                                        style={{ margin: 0, width: "80%" }}
                                        placeholder={`eg 'Dear ${
                                          setting === "first_name" ? "" : "Mr. "
                                        }{{${setting}}}'`}
                                        name="male"
                                        size="small"
                                        value={
                                          salutationSettings?.salutations[
                                            setting
                                          ].male || ""
                                        }
                                        onChange={(event) =>
                                          onUpdateSetting(event, setting)
                                        }
                                        error={checkError(setting, "male")}
                                      />
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                      <p className={styles.block_label}>
                                        Female
                                      </p>
                                      <TextfieldInput
                                        style={{ margin: 0, width: "80%" }}
                                        placeholder={`eg 'Dear ${
                                          setting === "first_name"
                                            ? ""
                                            : "Mrs. "
                                        } {{${setting}}}'`}
                                        name="female"
                                        size="small"
                                        value={
                                          salutationSettings?.salutations[
                                            setting
                                          ].female || ""
                                        }
                                        onChange={(event) =>
                                          onUpdateSetting(event, setting)
                                        }
                                        error={checkError(setting, "female")}
                                      />
                                    </Box>
                                  </>
                                )}
                                <Box display="flex" alignItems="center">
                                  <p className={styles.block_label}>
                                    {" "}
                                    {setting !== "no_name" && "Unknown"}
                                  </p>

                                  <TextfieldInput
                                    style={{ margin: "3px 0", width: "80%" }}
                                    placeholder={`${
                                      setting === "no_name"
                                        ? "eg. 'To whom it may concern'"
                                        : `eg. 'Dear Mr./Mrs. {{${setting}}}'`
                                    }`}
                                    name="unknown"
                                    size="small"
                                    value={
                                      setting === "no_name"
                                        ? salutationSettings?.salutations[
                                            setting
                                          ]
                                        : salutationSettings?.salutations[
                                            setting
                                          ].unknown || ""
                                    }
                                    onChange={(event) =>
                                      onUpdateSetting(event, setting)
                                    }
                                    error={
                                      setting === "no_name"
                                        ? checkError(setting, "no_name")
                                        : checkError(setting, "unknown")
                                    }
                                  />
                                </Box>
                              </div>
                              <div className={styles.block_icon}>
                                <DragHandle />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <p>{popupParagraph}</p>
        </div>
        <div className="action_container">
          <Button className="btn_cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="btn_confirm"
            style={{ width: "155px" }}
            onClick={handleSubmit}
          >
            {props.wordClicked ? "Edit placeholder" : "Add placeholder"}
          </Button>
        </div>
      </DialogPopup>
    </DragDropContext>
  );
}

export default SalutationsPlaceholderPopup;
